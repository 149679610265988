import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { addItem, increaseCost, reduceCost, retrieveData } from '../../services/storageService'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Alert, AlertTitle, Avatar, Button, Card, CardContent, CardHeader, CardMedia, Divider, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PurchaseButton from "../Purchase/PurchaseButton"
import DeleteIcon from '@mui/icons-material/Delete';
import Tips from '../../Core/Tips';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import "core-js/actual/array/group-by-to-map";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingTop: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },

    }),
);



export default function SumDetailInfo(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [purchase, setPurchase] = useState<any[]>([]);


    useEffect(() => {


    }, []);

    var path = [
        { name: "Домашняя", href: "/purchase" },
    ]

    var currentName = ""
    return (

        <div className={classes.root}>

            {
                (props.values != undefined) ?
                    <div /* style={{ paddingBottom: 30 }} */>
                        <div>
                            {/* <Alert severity="info">

                                <AlertTitle>Оформление заказа</AlertTitle>
                                
                                <div>Итоговая сумма: <strong> {props.values?.delivery_cost + props.values?.newCost} ₽</strong></div>
                            </Alert> */}

                            {
                                (props.emptyError)
                                    ?
                                    <div style={{ marginTop: 10 }}>
                                        <Alert severity="error" >
                                            <AlertTitle>Нельзя оформить пустой заказ</AlertTitle>
                                        </Alert>
                                    </div>
                                    : <div></div>
                            }

                            {
                                (props.shopError)
                                    ?
                                    <div style={{ marginTop: 10 }}>
                                        <Alert severity="error" >
                                            <AlertTitle>Минимальная сумма заказа из супермаркета 500 рублей</AlertTitle>
                                        </Alert>
                                    </div>
                                    : <div></div>
                            }

                            {
                                (props.restError)
                                    ? <div style={{ marginTop: 10 }}>
                                        <Alert severity="error">
                                            <AlertTitle>Минимальная сумма заказа из кафе или ресторана 100 рублей</AlertTitle>
                                        </Alert>
                                    </div>
                                    : <div></div>
                            }
                        </div>

                    </div>
                    : <div></div>
            }
        </div >
    );
}