import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Badge, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import { addItem, addItemWithOption, increaseCost, reduceCost, retrieveData } from '../../services/storageService';
import React from 'react';
import { Box, Breadcrumbs, ButtonBase, CircularProgress, Grid, IconButton, Link, Typography } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BasketButton from "../../Components/Purchase/BasketButton";
import './styles.css'
import Constructor from './Constructor';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootq: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            /* display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between' */
        },
        purchase_button: {
            alignSelf: 'flex-end',
            position: 'fixed',
            bottom: 450
        },
        mainConatinerStyle: {
            flexDirection: 'column',
            flex: 1
        }
    }),
);



export default function ItemDetail(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();
    const [flag, setfFlag] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [tagged, setTagged] = useState<any[]>([]);
    const [product, setProduct] = React.useState<any>({
        count: 0,
        cost: 0,
        delivery_cost: 0
    });

    useEffect(() => {
        fetch(process.env.REACT_APP_TITLE + `/product/detail?id=${params.id}`)
            .then(res => res.json())
            .then(
                (result: any) => {
                    setProduct(result)
                    setIsLoad(false)
                    //setSelectedTag(id)
                    //setItems(result)


                }
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.

            )

    }, []);

    var onPlusButtonPress = (id: number, info: string) => {
        increaseCost(id, info).then(() => {
            refresh()
        })
    }

    var onMinusButtonPress = (id: number, info: string) => {
        reduceCost(id, info).then(() => {
            refresh()
        })
    }


    var refresh = () => {
        // setIsLoaded(true)
        retrieveData().then((data: any) => {

            setfFlag(!flag);
            setTagged(data);
            // setIsLoaded(false)
        })
    }

    var addToBasket = async (value: any, sub_id: any, parent_id: any) => {

        if (value)
            var v = value
        v.sum = additionalSumm;
        v.info = additionalInfo;


        if (value.options == null) {
            //.navigate(`/detail/${value.id}`)
            addItem(v).then((value: any) => {
                refresh();
                // (props as any).setTextHolder(value)
            })
        }
        else {
            v.options = []
            addItemWithOption(v).then((value: any) => {
                navigate(`/item/${parent_id}/sub/${sub_id}`)
                // (props as any).setTextHolder(value)
            })
        }

    }
    const constructor1 = React.useRef<any>(null);
    const [additionalSumm, setAdditionalSumm] = React.useState(0);
    const [additionalInfo, setAdditionalInfo] = React.useState("");
    const addSum = (sum: any, additionalInfo: any) => {

        setAdditionalSumm(sum);
        setAdditionalInfo(additionalInfo)
    };

    useEffect(() => {
        /*  setIsLoaded(true) */
        retrieveData().then((data: any) => {

            setTagged(data);
            /*  setIsLoaded(false) */
        })

    }, []);

    return (
        <div>
            {
                isLoad
                    ?
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
                    : <div>
                        {
                            (product != undefined) ?


                                <Box>

                                    <Box sx={{/*  pt: 3, */ pb: 1 }}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link underline="hover" color="inherit" href="/">
                                                Главная
                                            </Link>
                                            <Link
                                                underline="hover"
                                                color="inherit"
                                                href={`/item/${product.parent}/sub/${product.tagId}`}
                                            >
                                                {product.tagName}
                                            </Link>
                                            <Typography >{product.name}</Typography>
                                        </Breadcrumbs>
                                    </Box>
                                    <Typography variant="h4">{product.name}</Typography>
                                    <Box sx={{ pt: 3 }}>
                                        <Grid container spacing={6}>
                                            <Grid item>
                                                <ButtonBase>
                                                    <img src={product.imageUrl} className="card_image_detail"
                                                        onError={(e: any) => {
                                                            e.onerror = null;
                                                            e.target.src = "https://avoska-dostavka.ru/avoska_images/A/default.png"
                                                        }}

                                                    />
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item xs={12} sm container>
                                                <Grid item xs container direction="column" spacing={2}>
                                                    <Grid item xs>
                                                        <Box sx={{ paddingBottom: 10 }} style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #21201f1a', justifyContent: 'space-between' }}>
                                                            <Box>
                                                                {
                                                                    product.newPrice == null
                                                                        ?
                                                                        additionalSumm == 0 ?
                                                                            <Typography variant="h6">{product.price} ₽ </Typography>
                                                                            : <Typography variant="h6">{product.price} ₽ + {additionalSumm} ₽ </Typography>


                                                                        /*   <div className="block__old_price">{product.price} ₽</div> */

                                                                        :
                                                                        <Typography className="price_block_for_sale">
                                                                            <Typography className="sale_block__new_price">{product.newPrice} ₽</Typography>
                                                                            <Typography className="sale_block__old_price">{product.price} ₽</Typography>

                                                                        </Typography>
                                                                }
                                                            </Box>
                                                            <Box>
                                                                <Button style={{ background: '#faa241', color: 'white', borderRadius: 10 }} endIcon={<AddIcon />} onClick={() => addToBasket(product, product.tagId, product.parent)}>
                                                                    В корзину
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ pt: 3 }}>
                                                            <Box>
                                                                <Typography variant="h6">О товаре  </Typography>
                                                            </Box>
                                                            <Box>
                                                                {product.description != null ?
                                                                    <Typography variant="subtitle2" gutterBottom>
                                                                        {product.description.split("\n").map((i: string, key: number) => {
                                                                            return <div key={key}>{i}</div>;
                                                                        })}

                                                                    </Typography>


                                                                    : <div></div>
                                                                }

                                                            </Box>
                                                        </Box>
                                                        {/*   <Typography variant="body2" gutterBottom>
                                                        Full resolution 1920x1080 • JPEG
                                                    </Typography>
                                                    <Typography variant="body2" >
                                                        ID: 1030114
                                                    </Typography> */}
                                                    </Grid>
                                                    {/* <Grid item>
                                                    <Typography variant="body2">
                                                        Remove
                                                    </Typography>
                                                </Grid> */}
                                                </Grid>
                                                {/*     <Grid item>

                                           
                                                <Typography variant="subtitle1" component="div">
                                                    $19.00
                                                </Typography>
                                            </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Box>


                                    {
                                        product.options != null
                                            ? <Constructor ref={constructor1} data={product.options} addSum={addSum}> </Constructor>
                                            : <div></div>
                                    }
                                </Box>
                                :
                                <div>
                                </div>
                        }


                    </div>
            }
           
                <BasketButton flag={flag}></BasketButton>
            
        </div>
    );
}