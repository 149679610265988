import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "./styles.css"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { AppBar, Avatar, Badge, Button, Card, CardContent, CardHeader, CardMedia, FormControl, FormHelperText, IconButton, Input, InputAdornment, Stack, TextField, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography'
import { addItem } from '../../../services/storageService';
import { addOtherThing } from '../../../services/otherThingService';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import PurchaseOtherForm from '../../Purchase/PurchaseOtherForm';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PersonIcon from '@mui/icons-material/Person';
import CancelIcon from '@mui/icons-material/Cancel';
import InputMask from "react-input-mask";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

            right: 10,
            bottom: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);




interface State {
    mobile: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SmthNewForm(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;

    const [password, setPassword] = useState<string>("");

    const [values, setValues] = React.useState<State>({
        mobile: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });


    const [users, setUsers] = React.useState<State>({
        mobile: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChange1 =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };




    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const onClick2 = async () => {


        /*        var reader = new FileReader()
               reader.onload = function (base64) {
       
                    
                   
                   var value = {
                       obj: { text: text },
                       imgs: base64
                   }
       
                   localStorage["file"] = base64;
           
                   addOtherThing(value).then((value: any) => {
                       navigate(`/basket`)
           
                       // (props as any).setTextHolder(value)
                   })
               } */
        // reader.readAsDataURL(files[0]);

        const formData = new FormData();
        formData.append("file", files[0]);

        localStorage.setItem('uploadedFile', JSON.stringify(formData));


        /*  try {
             const res = await axios.post(process.env.REACT_APP_TITLE + "/order/aa", formData);
             console.log(res);
         } catch (ex) {
             console.log(ex);
         } */
    }

    var createOrder = async () => {


        var data = JSON.stringify({
            otherThing: "Откуда забрать: " + startAddress + "  Описание:  " + text,
            total: 50,
            name: name,
            phone: values.mobile,
            address: address.replace(/[.-]/g, " "),// + " " + addressAll.replace(/[.-]/g, " "),
            products: [],
            changeMode: 1,
            deliveryMode: 1,
            code: "",
            comment: "",
            //userLogin: mobile
            // date:  `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`,
            // deliveryMode: (this.state as any).value,
            userLogin: 0

        });



        fetch(process.env.REACT_APP_TITLE + "/order",
            {
                method: "POST",
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            .then((response: any) => response.json())
            .then((json: any) => {

                files?.map(async (x: any) => {
                    try {

                        const formData = new FormData();
                        formData.append("file", x);
                        const res = await axios.post(process.env.REACT_APP_TITLE + "/order/aa", formData);
                        console.log(res);
                    } catch (ex) {
                        console.log(ex);
                    }

                })

                navigate(`/success`);

            })
            .catch((error) => {
                console.log(error);
                // (this.props as any).setActivatorState(false)
                return error;
            });




    }


    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleChangeMobile = (event: React.ChangeEvent<HTMLInputElement>) => {

        setMobile(event.target.value);
    };

    const handleStartAdress = (event: React.ChangeEvent<HTMLInputElement>) => {

        setStartAddress(event.target.value);
    };

    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };
    const [name, setName] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");

    const [startAddress, setStartAddress] = useState<string>("");

    const [files, setFiles] = useState<any>(null);
    const [imgForShow, setImgForShow] = useState<any>(null);
    var handleChange11 = (e: any) => {



        console.log(e.target.files);

        var f
        var img
        if (files == null) {
            f = []
            img = []
        }
        else {
            f = files.slice(0)
            img = imgForShow.slice(0)
        }


        /*   f?.push(URL.createObjectURL((e as any)?.target?.files[0])) */

        f?.push((e as any)?.target?.files[0])

        img?.push(URL.createObjectURL((e as any)?.target?.files[0]))
        setFiles(f);
        setImgForShow(img)

    }


    var deleteFiles = (e: any) => {

        var t = files.filter((x: any) => {
            return x != e
        })

        setFiles(t)
    }

    const [text, setText] = useState<string>("");
    const [value, setValue] = React.useState(0);


    const [file, setFile1] = useState();
    const [fileName, setFileName1] = useState();



    const saveFile = (e: any) => {
        /*      
            console.log(e.target.files[0]);
    
            var f
            if (files == null) {
                f = []
            }
            else {
                f = files.slice(0)
            }
    
            f?.push((e as any)?.target?.files[0])
            setFiles(f) */

        console.log(e.target.files);

        var f
        var img
        if (files == null) {
            f = []
            img = []
        }
        else {
            f = files.slice(0)
            img = imgForShow.slice(0)
        }


        /*   f?.push(URL.createObjectURL((e as any)?.target?.files[0])) */

        f?.push((e as any)?.target?.files[0])

        img?.push(URL.createObjectURL((e as any)?.target?.files[0]))
        setFiles(f);
        setImgForShow(img)
        /*   setFile1(e.target.files[0]);
          setFileName1(e.target.files[0].name); */
    };

    return (

        <Box sx={{ pt: 2 }}>


            <Box sx={{ p: 2, background: '#fff8e1'/* '#ede7f6' */, borderRadius: '12px' }} className='detail_card'>


                <div className="css-1iakqhe2">
                    <div className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm2"/*  focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DescriptionTwoToneIcon" */>
                        {/*    <path d="M13 4H6v16h12V9h-5V4zm3 14H8v-2h8v2zm0-6v2H8v-2h8z" opacity=".3"></path><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z">
                            </path> */}

                        <DeliveryDiningIcon sx={{ fontSize: 280, opacity: 0.3 }}></DeliveryDiningIcon>
                    </div>
                </div>
                {/*      <Typography>Корзина</Typography>
<div>Товаров в корзине:  <strong> {values?.count}</strong></div>
<div>на сумму <strong> {values?.newCost} ₽</strong></div>
{
    values?.cost != 0 && values?.cost - values?.newCost != 0
        ? <div>с учетом скидки <strong> {(values?.cost - values?.newCost)} ₽</strong></div>
        : <div></div>
}
<div>+ доставка <strong> {deliveryCost} ₽</strong></div> */}
                <Box mb={1} style={{ width: '100%', zIndex: 1 }}>
                    <Typography variant="h5" style={{ fontWeight: 600, color: 'black' }}>Шаг 1. Опиши детали</Typography>
                </Box>

                <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="caption">Укажите откуда требуется забрать заказ и укажите детали заказа (например, что требуется забрать доставку с wildberries), прикрепите фотографии, если требуются</Typography>
                </Box>

                <TextField
                    id="name_field"
                    value={startAddress}
                    label="Откуда забрать *"
                    variant="outlined"
                    style={{ width: '100%' }}
                    error={startAddress == ""}
                    onChange={handleStartAdress} />


                <TextField style={{ width: '100%', zIndex: 100, color: 'black' }}
                    id="outlined-multiline-static"
                    label="Описание"
                    value={text}
                    multiline
                    sx={{ mt: 2 }}
                    /*  color = {"black"} */
                    rows={4}
                    placeholder="Заберите, пожалуйста, посылку из Ozon"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setText(event.target.value);
                    }}

                />
                {/*  <div>Всего: <strong> {values?.delivery_cost + values?.newCost} ₽</strong></div> */}

                <Box sx={{ mt: 2, display: 'flex' }}>
                    <Button
                        startIcon={<CloudDownloadIcon />}
                        className='css-grmvr1'
                        /* style={{ background: '#FAA241', color: 'white', boxShadow: 'none', borderRadius: '10px' }} */
                        variant="contained"
                        component="label"
                    >
                        Добавить файлы
                        <input hidden type="file" onChange={saveFile} />
                    </Button>


                </Box>

                {
                    imgForShow?.length > 0
                        ? <Box sx={{ mt: 2, p: 2, /* backgroundColor: 'white', */ border: "1px solid black", borderRadius: '10px' }}>
                            <Stack direction="row" spacing={2}>

                                {
                                    imgForShow?.map((x: any) => {

                                        return (
                                            <Badge onClick={() => { return deleteFiles(x) }} badgeContent={
                                                <CancelIcon fontSize="small" className='delete_icon__purple' />
                                            }>
                                                <Avatar variant="rounded" src={x} />
                                            </Badge>
                                            /*   <Avatar alt="Remy Sharp" src={x} /> */
                                        )
                                    })
                                }
                            </Stack>
                        </Box>
                        : <></>
                }

            </Box>





            <Box sx={{ mb:10, mt: 2, p: 2, background: '#e3f2fd', borderRadius: '12px' }} className='info_card'>

                <div className="css-1iakqhe3">
                    <div className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm3"/*  focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DescriptionTwoToneIcon" */>
                        {/*    <path d="M13 4H6v16h12V9h-5V4zm3 14H8v-2h8v2zm0-6v2H8v-2h8z" opacity=".3"></path><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z">
                            </path> */}

                        <PersonIcon sx={{ fontSize: 280, opacity: 0.3 }}></PersonIcon >
                    </div>
                </div>

                <Typography variant="h5" style={{ fontWeight: 600, color: 'black' }} >
                    Шаг 2. Контактные данные
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="caption">После создания заказа с вами связаться менеджер для уточнения деталей</Typography>
                </Box>
                {/*   <Grid container  >
                    <Grid item xs={9}>
                        <Typography>Доставка</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography textAlign={'right'}>  {200} ₽</Typography>
                    </Grid>
                </Grid> */}
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ width: '100%' }}>
                        <Stack spacing={2}>



                            <TextField
                                id="name_field"
                                value={name}
                                label="Имя *"
                                variant="outlined"

                                error={name == ""}
                                onChange={handleChangeName} />

                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <InputMask
                                    mask="+7 (999) 999 9999"
                                    value={values.mobile}
                                    maskChar={"_"}
                                    onChange={handleChange1('mobile')}



                                >
                                    {() => <TextField
                                        label="Мобильный *"
                                        error={values.mobile == ""}
                                        margin="normal"
                                        type="text"
                                        style={{ width: '100%' }}
                                        sx={{ mt: 1 }}
                                        variant="outlined"
                                    />}
                                </InputMask>
                                {/*  <Typography variant="caption" gutterBottom>
                                Введите код из смс
                            </Typography> */}





                            </div>





                            <TextField
                                id="address_field"
                                value={address}
                                label="Адрес *"
                                variant="outlined"
                                error={address == ""}
                                onChange={handleChangeAddress} />


                        </Stack>
                    </Box>
                </Box>
            </Box>


            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'white', boxShadow: 'none' }}>
                <Toolbar>

                    <Box className="basket-button_bottom" /* endIcon={<ShoppingCartIcon />} */ onClick={() => { createOrder() }} style={{ background: '#ffe57f'/* '#804ed9' */, color: 'black', width: '100%' }}>
                        {/*  <Box> */}

                        <Box style={{ display: 'flex' }}>
                            <Typography >
                                30-40 мин
                            </Typography>
                        </Box>

                        <Box style={{ display: 'flex' }}>
                            <Typography >
                                Оформить
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            {/*  <div>
                                <Chip label={values.count} color="primary" />
                            </div> */}
                            <div>
                                <div style={{ /* background: 'white', */ color: 'black' }} className="basket-button_chip">
                                    {200 + " ₽"}
                                </div>
                            </div>
                        </Box>

                        {/*   </Box> */}
                    </Box>
                    {/*      {
                    props.disabled
                        ? <Box className="basket-button_bottom"   style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography >
                                    Оформить заказ
                                </Typography>
                            </Box>
                        </Box>
                        : <Box className="basket-button_bottom"  onClick={() => { navigate(`/purchase`); }} style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography >
                                    Оформить заказ
                                </Typography>
                            </Box>
                        </Box>
                } */}

                </Toolbar>
            </AppBar>
        </Box >

    );
}