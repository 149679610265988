import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { AppBar, Avatar, Button, Card, CardContent, CardHeader, CardMedia, FormControl, FormControlLabel, FormLabel, IconButton, Input, InputLabel, Link, Modal, Radio, RadioGroup, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Item } from '../../model/Item';
import OrderButton from './OrderButton'
import { clearBasket, retrieveData } from '../../services/storageService';
import { IMaskInput } from 'react-imask';
import { deleteUser, getUser, setUser } from '../../services/userStorage';
import { User } from '../../model/User';
import CircularProgress from '@mui/material/CircularProgress';
/* import OrderDetail from '../Order/OrderDetail'; */
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import EditIcon from '@mui/icons-material/Edit';
import SumDetailInfo from './SumDetailInfo';
import PhoneNumberField from '../Auth/PhoneNumberField';
import { MaskField } from 'react-mask-field';
import InputMask from "react-input-mask";
import TimeSlider from '../../Core/TimeSlider';
import "./styles.css"
import { getOtherThing } from '../../services/otherThingService';
import axios from 'axios';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'background.paper',

    p: 1,
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const steps = ['Адрес', 'Доп инфрмация'];



export default function PurchaseForm(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;

    const [name, setName] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [promo, setPromo] = useState<string>("");
    const [deliveryMode, setDeliveryMode] = useState<string>("1");
    const [isLoad, setLoad] = useState<boolean>(false);
    const [changeMode, setChangeMode] = useState<string>("1");
    const [otherThing, setOtherThing] = useState<string>("");
    const [files, setFiles] = useState<any>(null);
    useEffect(() => {
        getOtherThing().then((user: any) => {
           
  
                setOtherThing(user?.obj?.text)
                setFiles(user?.obj?.imgs)
            
          
        })



    }, []);


    function getPhoto() {
         
        var base64 = localStorage["file"];
        var base64Parts = base64.split(",");
        var fileFormat = base64Parts[0].split(";")[1];
        var fileContent = base64Parts[1];
        var file = new File([fileContent], "file name here", {type: fileFormat});
        return base64;
     }

    const [values, setValues] = React.useState<any>({
        count: 0,
        cost: 0,
        newCost: 0,
        delivery_cost: 0
    });


    var getDeliveryPrice = (data: any) => {

        var res = data.find((x: any) => {
            return x.obj.store != null;
        })

        var resShop = data.find((x: any) => {
            return x.obj.store == null;
        })
        /* var res = data.filter((x: any) => {
            return (x.obj.tagId >= 131 && x.obj.tagId <= 138) ||
                (x.obj.tagId >= 152 && x.obj.tagId <= 154) ||
                (x.obj.tagId >= 140 && x.obj.tagId <= 140) ||
                (x.obj.tagId >= 156 && x.obj.tagId <= 158) ||
                (x.obj.tagId >= 171 && x.obj.tagId <= 179)
        }
        ) */
        var sum = 0;
        sum += res == null ? 0 : 199
        sum += resShop == null ? 0 : 49

        return sum;

    }

    useEffect(() => {


        var now = new Date();
        var v = times_def;

        var times_today: any[] = []
        //  times_today.concat(times_def);

        times_today.push.apply(times_today, times_def);
        var a = times_today.filter(x => x.start >= now.getHours() + 2);


        a.forEach(x => x.day = "сегодня");
        var t: any[] = [];

        t.push(start)
        t.push(...a)
        t.push(...times_tommorow)
        //a.push(...times_tommorow)


        getUser().then((user: User) => {

            if (user != null) {

                setName(user.userNane)
                setAddress(user.address)
                setMobile(user.phone)
            }
            else {
                navigate("/login/purchase")
                // setAuthMode(false)
            }
        })
        setЕimes(t)

        retrieveData().then((data: any) => {
            data.data.sort((a: any, b: any) => {

                var aa = a.obj.store != undefined ? a.obj.store.id : 10;
                var bb = b.obj.store != undefined ? b.obj.store.id : 10;

                if (aa < bb) {
                    return -1;
                }
                if (aa > bb) {
                    return 1;
                }
                return 0;
            }
            )

            // setPurchase(data.data);
            // checkSum(data.data)
            setValues({
                count: data.count,
                cost: Math.round(data.cost),
                newCost: Math.round(data.newCost),
                delivery_cost: getDeliveryPrice(data.data)
            })

            checkSum(data.data)
        })

    }, []);


    var sendOrder = async () => {

        setLoad(true)
        getUser().then(async (user: User) => {


             
          /*   var file = getPhoto()
            console.log(file);
            const formData = new FormData();
            formData.append("formFile", file); */
    


          /*   try {
                const res = await axios.post(process.env.REACT_APP_TITLE + "/order/aa", formData);
                console.log(res);
              } catch (ex) {
                console.log(ex);
              }
               */
           /*  fetch(process.env.REACT_APP_TITLE + "/order/aa",
            {
                method: "POST",
                body: formData,
                headers: {
                    'Accept': "application/json",
                    'Content-Type': 'multipart/form-data'
                }
            })

            .then((response: any) => response.json())
            .then((json: any) => {
                clearBasket();
                navigate(`/success`);

            })
            .catch((error) => {
                console.log(error);
                // (this.props as any).setActivatorState(false)
                return error;
            }); */
        
            /////




            const current = new Date();

         
            retrieveData().then((value: any) => { 
                var data = JSON.stringify({
                    otherThing: otherThing,
                    files: files,
                    total: values?.delivery_cost + values?.newCost,
                    name: name,
                    phone: mobile,
                    address: address.replace(/[.-]/g, " "),// + " " + addressAll.replace(/[.-]/g, " "),
                    products: value.data.map((elem: any) => {
                        return (
                            {
                              
                                productId: elem.obj.id,
                                count: elem.count,
                                name: elem.obj.name,
                                price: elem.obj.price,
                                newPrice: elem.obj.newPrice ?? 0,
                                image: elem.obj.imageUrl,
                                tagId: elem.obj.tagId,
                                info: elem.obj.info,
                                additionalSum: elem.obj.sum ?? 0,
                                store: {
                                    id: elem.obj.store?.id,
                                    name: elem.obj.store?.name
                                }
                            }
                        )
                    }),
                    changeMode: changeMode,
                    deliveryMode: selectedTime,
                    code: promo,
                    comment: comment,
                    //userLogin: mobile
                    // date:  `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`,
                    // deliveryMode: (this.state as any).value,
                    userLogin: user != undefined ? user.phone : 0

                });



                /////

             
                
                fetch(process.env.REACT_APP_TITLE + "/order",
                    {
                        method: "POST",
                        body: data,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })

                    .then((response: any) => response.json())
                    .then((json: any) => {
                        clearBasket();
                        navigate(`/success`);

                    })
                    .catch((error) => {
                        console.log(error);
                        // (this.props as any).setActivatorState(false)
                        return error;
                    });

            });

            
            if (user.address == '' && user.userNane == '') {

                var a = user.access_token;
                var obj = {
                    Login: mobile,
                    Phone: mobile,
                    OldPhone: mobile,
                    Name: name,
                    Address: address.replace(/[.-]/g, " ")// + " " + addressAll.replace(/[.-]/g, " "),    
                }
                fetch(process.env.REACT_APP_TITLE + "/account/update",
                    {
                        method: "POST",
                        body: JSON.stringify(obj),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + a,
                        }
                    })

                    .then((response: any) => {

                        if (response.status == 400 || response.status == 401) {
                            navigate("/user")
                        }
                        else {
                            return response.json()
                        }
                    })
                    .then((res: User) => {
                        if (res != undefined) {
                            setUser(res);
                            //setUser(res);
                            // navigate("/user")
                            return res;
                        }
                    });

            }
            else {

            }
        })



    }

    const [isValidSum, setIsValidSum] = useState<boolean>(true);
    const [open, setOpen] = React.useState(false);
    const [addressAll, aetAddressAll] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [phone, setPhone] = React.useState<any>({
        textmask: '(100) 000-0000',
        numberformat: '1320',
    });

    var checkSum = (data: any) => {



        var rest = data.filter((x: any) => {
            return x.obj.store != null;
        })
        const sumRest = rest.map((x: any) => x.obj.price * x.count).reduce(add, 0)

        var shop = data.filter((x: any) => {
            return x.obj.store == null;
        })
        const sumShop = shop.map((x: any) => (x.obj.newPrice != null ? x.obj.newPrice : x.obj.price) * x.count).reduce(add, 0)

        var r = false
        var shopError = false
        var restError = false
        var emptyError = false
        if (rest.length == 0 && shop.length == 0 ) {
            r = true
            emptyError = true
        }
        else {
            if (rest.length > 0) {
                if (sumRest < 100) {
                    restError = true
                    r = true
                }
            }
            else {
                if (sumShop < 500) {
                    shopError = true
                    r = true
                }
            }

            if (shop.length > 0) {
                if (sumShop < 500) {
                    shopError = true
                    r = true

                }
            }
        }


        setIsValidSum(r)

        setShopError(shopError)
        setRestError(restError)
        setEmptyError(emptyError)
    }


    const [shopError, setShopError] = useState<boolean>(false);
    const [restError, setRestError] = useState<boolean>(false);



    const [emptyError, setEmptyError] = useState<boolean>(false);
    var add = (accumulator: any, a: any) => {
        return accumulator + a;
    }
    const [value, setValue] = React.useState('female');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangeMode((event.target as HTMLInputElement).value);

    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleChangeMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMobile(event.target.value);
    };


    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    var setAddressFromMap = (name: string) => {
        setAddress(name);
        setOpen(false)
    }
    const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };



    var radionWitnDescription = (text1: string, text2: string) => {
        return <Box>
            <Typography variant="body2">{text1}</Typography>
            <Typography variant="caption" style={{color: 'grey' }}>{text2}</Typography>
        </Box>
    }


    const change_account = () => {
        deleteUser();
        navigate("/login/purchase")
    }

    var isValidTime = () => {
        return (new Date()).getHours() >= 0 && (new Date()).getHours() <= 9
    }

    const [selectedTime, setSelectedTime] = React.useState(!isValidTime() ? -1 : 7);
    const [times, setЕimes] = React.useState<any[]>([]);



    var start = { start: -1, end: -1, price: "бесплатно", id: -1, day: "" };

    var times_def = [
        { start: 10, end: 12, price: "бесплатно", id: 2, day: "" },
        { start: 12, end: 14, price: "бесплатно", id: 3, day: "" },
        { start: 14, end: 16, price: "бесплатно", id: 4, day: "" },
        { start: 17, end: 19, price: "бесплатно", id: 5, day: "" },
        { start: 20, end: 22, price: "бесплатно", id: 6, day: "" }
    ];

    var times_tommorow = [
        { start: 10, end: 12, price: "бесплатно", id: 7, day: "завтра" },
        { start: 12, end: 14, price: "бесплатно", id: 8, day: "завтра" },
        { start: 14, end: 16, price: "бесплатно", id: 9, day: "завтра" },
        { start: 17, end: 19, price: "бесплатно", id: 10, day: "завтра" },
        { start: 20, end: 22, price: "бесплатно", id: 11, day: "завтра" }
    ];
    var onchangeTime = (id: number) => {

        setSelectedTime(id)
    }
    return (
        <div className={classes.root}>
            {
                isLoad
                    ?
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
                    : <div>
                        <div style={{ paddingBottom: 50 }}>
                            <SumDetailInfo values={values} shopError={shopError} restError={restError} emptyError={emptyError}></SumDetailInfo>
                            <Box sx={{ width: '100%' }}>

                                <Box sx={{ p: 2, background: '#f8f7f5', borderRadius: '12px' }} >
                                    <Typography variant="h6" gutterBottom component="div">
                                        Контактные данные
                                    </Typography>

                                    <TextField
                                        id="name_field"
                                        value={name}
                                        label="Имя *"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        error={name == ""}
                                        onChange={handleChangeName} />

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <InputMask
                                            mask="+7 999 999 9999"
                                            value={mobile}

                                            style={{ color: 'lightgray' }}
                                            onChange={handleChangeMobile}
                                        >
                                            {() => <TextField

                                                variant="standard"
                                                label="Мобильный *"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                error={mobile == ""}
                                                margin="normal"
                                                type="text"

                                            />}
                                        </InputMask>
                                        <Typography onClick={change_account} variant="caption" style={{ textAlign: 'end' }}>     Сменить аккаунт </Typography>
                                        {/*  <Button  variant="text" onClick={change_account} style={{ color: 'white', background: '#ff8601' }}>
                                                Сменить аккаунт
                                            </Button> */}
                                    </div>
                                </Box>
                                {/* 
                                    <MaskField mask="+_ __________" replacement={{ _: /\d/ }} modify={modify} />
                                    <InputMask
                                        mask="(0)999 999 99 99"
                                        value={phone}
                                        disabled={false}
                                    
                                        onChange={(e) => setPhone(e.target.value)}
                                    >
                                        {() => <TextField />}
                                    </InputMask> */}


                                {/* <PhoneNumberField></PhoneNumberField> */}

                                {/*   <FormControl>
                    
                        <Input  
                            value={phone}
                            onChange={handleChange}
                            name="textmask"
                            id="input-with-icon-adornment"
                         
                            inputComponent={TextMaskCustom as any}
                        />


                    </FormControl> */}

                                <Box sx={{ mt: 2, p: 2, background: '#f8f7f5', borderRadius: '12px' }} >
                                    <Typography variant="h6" gutterBottom component="div">
                                        О доставке
                                    </Typography>

                                    {/* <Link
                                        style={{ textAlign: 'start' }}
                                        component="button"
                                        variant="body2"
                                        onClick={handleOpen}
                                    >
                                        {address != '' ? address : 'Выберите адрес'}
                                        <EditIcon fontSize='small'></EditIcon>
                                    </Link>
                                    <TextField
                                        id="mobile_field"
                                        label="Улица, дом, квартира ..."
                                        value={addressAll}
                                        disabled={address == ""}
                                        variant="outlined"
                                        onChange={handleChangeAddressAll} /> */}

                                    <TextField
                                        id="address_field"
                                        value={address}
                                        label="Адрес *"
                                        type="text"
                                        variant="standard"
                                        error={address == ""}
                                        style={{ width: '100%' }}
                                        onChange={handleChangeAddress} />

                                    {/* <TimeSlider items={times} selectedTime={selectedTime} onchangeTime={onchangeTime}></TimeSlider> */}
                                    {/*        <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="diliverty_mode_field"
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="fast" control={<Radio />} label="Экспресс-доставка" />
                                            <FormControlLabel value="slow" control={<Radio />} label={radionWitnDescription("К определенному времени", "Укажите время в комментарии к заказу")} />
                                        </RadioGroup>
                                    </FormControl> */}


                                    <Box sx={{mt:2, mb:2}}>
                                        {/*  <FormLabel id="demo-controlled-radio-buttons-group">Что делать, если какой-нибудь товар из Вашего закза уже раскупили?</FormLabel> */}
                                        <RadioGroup
                                            /*  aria-labelledby="demo-controlled-radio-buttons-group"
                                             name="change_mode_field" */
                                            value={changeMode}
                                            onChange={handleRadioChange}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label={radionWitnDescription("Не звонить, удалить товар из заказа", "")} />
                                            <FormControlLabel value="2" control={<Radio />} label={radionWitnDescription("Не звонить, подобрать для Вас замену", "Итоговая стоимость заказа может немного измениться")} />
                                            <FormControlLabel value="3" control={<Radio />} label={radionWitnDescription("Позвонить Вам для принятия решения", "Может повлиять на скорость доставки заказа")} />
                                        </RadioGroup>
                                    </Box>

                                    <TextField
                                        id="outlined-textarea"
                                        label="Комментарий"
                                        variant="standard"
                                        value={comment}
                                        onChange={handleChangeComment}
                                        multiline
                                        style={{ width: '100%' }}
                                    />

                                    {/*          <TextField

                                        value={promo}
                                        onChange={handleChangePromo}
                                        id="outlined-basic"
                                        label="Промо-код" variant="outlined" /> */}
                                </Box>
                            </Box>

                            <Box>

                            </Box>

                        </div>
                        {/*  <div>
                            <OrderButton
                                disabled={isValidSum || address == "" || name == "" || mobile == ""}
                                onClick={() => sendOrder()}

                            ></OrderButton>
                        </div> */}

                        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'white', boxShadow: 'none' }}>
                            <Toolbar>

                                <Box className="basket-button_bottom" /* endIcon={<ShoppingCartIcon />} */ onClick={sendOrder} style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                                    {/*  <Box> */}


                                    <Box style={{ display: 'flex' }}>
                                        <Typography >
                                            Заказать
                                        </Typography>
                                    </Box>
                                </Box>
                                {/*      {
                    props.disabled
                        ? <Box className="basket-button_bottom"   style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography >
                                    Оформить заказ
                                </Typography>
                            </Box>
                        </Box>
                        : <Box className="basket-button_bottom"  onClick={() => { navigate(`/purchase`); }} style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography >
                                    Оформить заказ
                                </Typography>
                            </Box>
                        </Box>
                } */}

                            </Toolbar>
                        </AppBar>


                    </div>

            }


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    {/*   <OrderDetail setAddressFromMap={setAddressFromMap}></OrderDetail> */}
                </Box>
            </Modal>
        </div >
    );
}