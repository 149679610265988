import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Avatar, IconButton, Toolbar, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import MenuIcon from '@mui/icons-material/Menu';

import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import LogoIcon from '../Components/svg/LogoIcon';
import Logo from '../Components/svg/Logo';
import BasketButton from '../Components/Purchase/BasketButton';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function Navbar(props: any) {
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });



    const onHistoryClick = (event: React.SyntheticEvent | Event, reason?: string) => {
        navigate(`/history`);
    };

    const onFriendClick = () => {
        navigate(`/friends`);
    }

    const onSpeedTestClick = () => {
        window.open("https://speedtest.whypn.com/", "_blank")
    }

    const onLogoClick = () => {
        navigate(`/`);
    }

    const onTelegramTestClick = () => {
        navigate(`/test`);
    }

    const onSupportClick = () => {
        navigate(`/support`);
    }
    const onNewClick = () => {
        navigate(`/new`);
    }
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

/* 
            <MenuItem onClick={() => navigate("/about")}>
            <Typography textAlign="center">О доставке</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate("/contacts")}>
            <Typography textAlign="center">Контакты</Typography>
          </MenuItem> */

          
    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
     {/*        <Box p={2}  >
                <Typography style={{ color: '#ffffff' }}>Привет, {props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.username : "no auuth"}</Typography>
            </Box> */}
            <Divider />
            <List>
      {/*           <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onNewClick}>
                        <ListItemIcon style={{ color: props.tg.themeParams.text_color ?? '#ffffff' }}>
                            <PaymentIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Новое подключение"} />
                    </ListItemButton>
                </ListItem> */}

                <ListItem key={1} disablePadding>
                    <ListItemButton  onClick={() => navigate("/about")}>
                        <ListItemIcon style={{ color: 'black' }}>
                            <ReceiptLongIcon />
                        </ListItemIcon>
                        <ListItemText primary={"О доставке"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={1} disablePadding>
                    <ListItemButton onClick={() => navigate("/contacts")}>
                        <ListItemIcon style={{ color: 'black' }}>
                            <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Контакты"} />
                    </ListItemButton>
                </ListItem>

    


             {/*    <ListItem key={1} disablePadding>

                    <ListItemButton onClick={onTelegramTestClick}>
                        <ListItemIcon style={{ color: props.tg.themeParams.text_color ?? '#ffffff' }}>
                        
                        </ListItemIcon>
                        <ListItemText primary={"Wallet Pay β"} />
                    </ListItemButton>
                </ListItem> */}
                {/*   {['История', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))} */}
            </List>

        </Box>
    );

    return (
        <div>

            <Box sx={{ flexGrow: 1 }}>
                <FormGroup>

                </FormGroup>
                <AppBar  position="static" style={{  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;', background:'white' }}>
                    <Toolbar>
                        <div>
                          {/*   <Avatar alt="Remy Sharp"  src={require("../assets/images/group.png")} /> */}

                            <LogoIcon onClick={() => navigate("/")}></LogoIcon>
            {/*   <Logo onClick={() => navigate("/")}></Logo> */}

                        </div>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center' onClick={onLogoClick} style={{ cursor: 'pointer', color: 'black' }}>
                            Авоська
                        </Typography>

          
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            style={{ cursor: 'pointer', color: 'black' }}
                            onClick={toggleDrawer('right', true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
          
                 <React.Fragment key={'right'}>



                    <Drawer

                        anchor={'right'}
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}                       >
                        {list('right')}
                    </Drawer>
                </React.Fragment>
            
        </div>
    );
}
