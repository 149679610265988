import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Alert, Badge, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect } from 'react';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { getUser } from '../services/userStorage';
import { User } from '../model/User';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import "./styles.css"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      /* flexGrow: 1,
      position: 'absolute',
      right: 20,
      bottom: 20 */

      //position: 'fixed',
      bottom: 120,
      right: 20
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);



export default function CurrentStatus(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  var tags = (props as any).tags;
  const [status, setStatus] = React.useState<any>("");
  const [values, setValues] = React.useState<any>({
    count: 0,
    cost: 0,
    delivery_cost: 0
  });

  useEffect(() => {
    getUser().then((user: User) => {
 
      if (user != null) {
        var token = user.access_token;
        var phone = user.phone;
        fetch(process.env.REACT_APP_TITLE + `/status/current?phone=${phone}`, {
          method: "GET",

          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }
        )
          .then(response => {

            if (response.status == 404 || response.status == 401) {
            
            
              //  navigate("/login/user")
            }

            return response.text()
          })
          .then(
            (result: string) => {


              setStatus(result)

            }
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.

          )

      }
      else {
        // navigate("/login")
        // setAuthMode(false)
      }
    })


  }, []);

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const getStatusMessage = () => {
    var a = <div></div>

    if (status == 'Создан\n')
      a = <AnnouncementIcon />
    if (status == 'В работе')
      a = <ShoppingCartIcon />
    if (status == "Едет к вам")
      a = <LocalShippingIcon />
    return <div style={{ display: 'flex' }}>{a}<div style={{ paddingLeft: 5 }}>Заказ:   <Link underline="none" color="inherit" href="/history">{status}</Link></div></div>
  }
  const action = (
    <React.Fragment>


      <LinearProgress />
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <LinearProgress color="secondary" />
        <CloseIcon fontSize="small" />
      </IconButton>

      <LinearProgress />
    </React.Fragment>
  );
  return (
    <div>
      {
        status != "" && status != "Отменен" ?
          <Snackbar
            style={{
              right: 20,
              bottom: 20,
              width: '60%',
              height: 35,
              position: "fixed"

            }}

            autoHideDuration={6000}
            open={open}
            message={getStatusMessage()}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <LinearProgress color="secondary" />
                <CloseIcon fontSize="small" />
              </IconButton>
            }
            sx={{ bottom: { xs: 70, sm: 0 } }}
          />
          : <div></div>
      }
    </div>
  );
}