import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css'
import ButtonBase from '@mui/material/ButtonBase';
import ComputerIcon from '@mui/icons-material/Computer';
import LocalMallIcon from '@mui/icons-material/LocalMall';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /*   position: 'fixed',
              bottom: 20,
              right: 20 */
            paddingBottom: 10
        },
        buttonStep: {
            width: "150px",
            height: "49px",

        }

    }),
);

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));


export default function SmthNew(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;

    var onClick = () => {
        navigate("/smth_new")
    }


    return (
        <Box className={classes.root}>

            <Box 
                onClick={onClick}
                className="css-jvzmdc1  css-1y3mnrk" sx={{ p: 2, borderRadius: '12px', height: '80%', background: '#d84315', mt:2 }}>
                {/*  <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root css-jvzmdc"> */}
                <div className="css-18mhetb">
                    <div className="css-1iakqhe1">
                        <div className=" css-6flbmm1"/*  focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DescriptionTwoToneIcon" */>
                            {/*    <path d="M13 4H6v16h12V9h-5V4zm3 14H8v-2h8v2zm0-6v2H8v-2h8z" opacity=".3"></path><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z">
                            </path> */}

                            <LocalMallIcon sx={{ fontSize: 80, opacity: 0.3 }}></LocalMallIcon>
                        </div>
                    </div>


                    <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1 MuiGrid-direction-xs-column css-mgf9hi">
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 css-h719to">
                            {/*  <div className=' MuiTypography-root MuiTypography-h3 MuiTypography-alignCenter css-aa5ry5'><h2>Подключи свой ПК к WhyPN</h2></div> */}

                            <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
                                <Typography variant="h6">Доставить что-то еще</Typography>
                                {/*   <Typography style={{  }} variant="subtitle2">Всего за 90 рублей в месяц!</Typography> */}

                            </div>


                            {/*  <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-alignCenter css-aa5ry5">Подключи свой ПК к WhyPN</h3> */}
                        </div>
                        {/* <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 css-h719to">
                            <p className="MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-1sgcksc">Daily page view</p>
                        </div> */}
                    </div>
                </div>
                {/*  </div> */}
            </Box>


            {/* <Box  className={"smth_new"} onClick={onClick}>
    <Typography>Доставить что-то еще</Typography>
</Box>
       */}
        </Box>
    );
}