import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { AppBar, Avatar, Button, Card, CardContent, CardHeader, CardMedia, IconButton, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /*   flexGrow: 1,
              position:'absolute',
              right: 10,
              bottom: 10 */
            position: 'fixed',
            bottom: 20,
            right: 20
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function PurchaseButton(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    return (

        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'white', boxShadow: 'none' }}>
            <Toolbar>

                <Box className="basket-button_bottom" /* endIcon={<ShoppingCartIcon />} */ onClick={()=>{navigate(`/purchase`);}} style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                    {/*  <Box> */}

                    <Box style={{ display: 'flex' }}>
                        <Typography >
                            30-40 мин
                        </Typography>
                    </Box>
 
                    <Box style={{ display: 'flex' }}>
                        <Typography >
                            Оформить
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {/*  <div>
                                <Chip label={values.count} color="primary" />
                            </div> */}
                        <div>
                            <div style={{ /* background: 'white', */ color: 'white' }} className="basket-button_chip">
                                {props.sum + " ₽"}
                            </div>
                        </div>
                    </Box>

                    {/*   </Box> */}
                </Box>
                {/*      {
                    props.disabled
                        ? <Box className="basket-button_bottom"   style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography >
                                    Оформить заказ
                                </Typography>
                            </Box>
                        </Box>
                        : <Box className="basket-button_bottom"  onClick={() => { navigate(`/purchase`); }} style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography >
                                    Оформить заказ
                                </Typography>
                            </Box>
                        </Box>
                } */}

            </Toolbar>
        </AppBar>
    )
    /*     <div className={classes.root}>
            
            <Button variant="contained" onClick={()=>{navigate(`/purchase`);}} disabled={props.disabled} style={{color:'white', background:'#ff8601'}}>
                Оформить заказ
            </Button>

        </div>
    ); */
}