import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect } from 'react';
import { retrieveData } from '../../services/storageService';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Badge, Button, Chip } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flexGrow: 1,
            position: 'absolute',
            right: 20,
            bottom: 20 */
            /*      width: '100%', */
            position: 'fixed',
            bottom: 20,
            right: 20
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function BasketButton(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [values, setValues] = React.useState<any>({
        count: 0,
        cost: 0,
        delivery_cost: 0
    });

    useEffect(() => {
        retrieveData().then((value: any) => {

            var c = 0;
            for (var i = 0; i < value.data.length; i++)
                c += value.data[i].count;
            setValues({
                count: c,
                cost: Math.round(value.cost),
                newCost: Math.round(value.newCost),
                delivery_cost: 0
            })
            /* this.setState({
                count: c,
                cost: value.cost,
                deliveryPrice: this.getDeliveryPrice(value.data),
                cost_all: Math.floor(value.cost) + Math.floor(this.getDeliveryPrice(value.data)) ,
            }); */
        })

    }, [props.flag]);


    return (

        /*  <Button className="basket-button_bottom" endIcon={<ShoppingCartIcon />} variant="contained" onClick={() => { navigate(`/basket`); }} style={{ background: '#FAA241', color: 'white', width: '100%' }}>
             Корзина
         </Button> */

        <AppBar position="fixed"   sx={{ top: 'auto', bottom: 0, background: 'white', boxShadow: 'none' }}>
            <Toolbar>
                <Box className="basket-button_bottom" /* endIcon={<ShoppingCartIcon />} */ onClick={() => { navigate(`/basket`); }} style={{ background: '#FAA241', color: 'white', width: '100%' }}>
                    {/*  <Box> */}

                    <Box style={{display:'flex'}}>
                        <Typography >
                            30-40 мин
                        </Typography>
                    </Box>

                    <Box style={{display:'flex'}}>
                        <Typography >
                            Корзина
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {/*  <div>
                                <Chip label={values.count} color="primary" />
                            </div> */}
                        <div>
                            <div style={{ /* background: 'white', */ color: 'white' }} className="basket-button_chip">
                                {values.newCost + " ₽"}
                            </div>
                        </div>
                    </Box>

                    {/*   </Box> */}
                </Box>
            </Toolbar>
        </AppBar>
        /*       <div className={classes.root}>
                  <Badge anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                  }} color="secondary" badgeContent={values.count}>
      
      
                      <Badge anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                      }} badgeContent={values.newCost + " ₽"} color="secondary">
      
      
                          <Button  className="basket-button_bottom"   endIcon={<ShoppingCartIcon />} variant="contained" onClick={() => { navigate(`/basket`); }} style={{ background: '#FAA241', color: 'white',width:'100%' }}>
                              Корзина
                          </Button>
      
                      </Badge>
                  </Badge>
              </div>
          ); */
    )
}