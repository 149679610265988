import * as React from "react"
import { SVGProps } from "react"

const LogoIconSvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
    width={30}
    height={30}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.47} cy={27.47} r={27.47} fill="#FF9622" />
    <g clipPath="url(#a)" stroke="#fff" strokeWidth={2.867}>
      <path d="M28.048 28.22c4.335 0 8.167-2.731 9.862-4.147a1.68 1.68 0 0 0 .554-.882 1.701 1.701 0 0 0-.069-1.044c-3.46-8.392-8.57-12.6-10.324-12.6h-.174c-1.75 0-6.862 4.208-10.325 12.6a1.687 1.687 0 0 0-.071 1.045c.088.347.284.656.558.881 1.695 1.408 5.52 4.148 9.853 4.148h.136z" />
      <path d="M27.805 7.165c-5.306 0-14.415 11.92-15.35 21.646 0 0-1.348 15.789 15.43 16.306h.018c16.777-.514 15.43-16.306 15.43-16.306-.956-9.726-10.065-21.646-15.351-21.646h-.177zm10.711 15.472s-.984 19.145-20.446 19.145m19.825-16.516c-8.697 7.152-12.873 10.193-24.67 10.193m4.225-12.822s.983 19.145 20.445 19.145M18.07 25.266c8.697 7.152 12.873 10.193 24.67 10.193" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(10.418 4.584)"
          d="M0 0h34.952v42.962H0z"
        />
      </clipPath>
    </defs>
  </svg>
)

export default LogoIconSvgComponent
