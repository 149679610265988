import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import PriceField from "./PriceField";
import './styles.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Grid from '@mui/material/Grid';
import { Skeleton } from "@mui/material";
const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};


export default function TestSkeleton(props: any) {
    const [page, setPage] = useState<number>(1);
    const [current, setCurrent] = useState<any[]>(props.data);
    const [state, setState] = useState<any[]>(props.data);
    const navigate = useNavigate();


    var fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        // var id = result[0].id
        fetch(process.env.REACT_APP_TITLE + `/product?tagId=${props.id}&mode=${0}&page=${page}`)
            .then(res => res.json())
            .then(
                (result: any[]) => {

                    var a = state;
                    setState(
                        a.concat(result)
                    )
                    setPage(page + 1)
                    setCurrent(result)
                }
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.

            )



    }


    var navigateToDeatail = (value: any) => {

        navigate(`/detail/${value.id}`)
        /*   addItem(value).then((value: any) => {
                refresh();
                // (props as any).setTextHolder(value)
            }) */

    }
    const image = {
        alt: "image",
        src: "https://picsum.photos/200/300",
        height: "400px",
        width: "400px",
        caption: "captions"
    };
    return (
        <div>
            {/*  <Grid container spacing={{ xs:4, md:3 }}  columns={{ xs:4, sm: 8, md: 12 }}>
                    {state.map((x, index) => (
                         <Grid item xs={2} sm={4} md={4} ></Grid> */}


            <Grid container spacing={{ xs: 4, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x, index) => (
                    <Grid item xs={2} sm={4} md={4}  width={'100%'}>

                        <Skeleton variant="rectangular" className="card-item" height={140} />

                        {/*    <Card className="card-item">
                                <CardActionArea

                                >

                                    <div className="card_image_block">
                                              <CardMedia

                                            component="img"
                                            className="card_image"
                                            style={{ borderRadius: 20 }}
                                            
                                            src={x.imageUrl}
                                            onError={(e :any)=> { 
                                                e.onerror = null;
                                                e.target.src = "https://avoska-dostavka.ru/avoska_images/A/default.png"}}
                                            onClick={() => {

                                                 navigateToDeatail(x)
                                            }}
                                        />
                                    </div>

                                    <CardContent className="product_card" style={{ padding: 10, width: '100%' }}>

                                        <div>
                                            {
                                                x.newPrice == null
                                                    ?

                                                    <div className="block__old_price">{x.price} ₽</div>

                                                    :
                                                    <div className="price_block_for_sale">
                                                        <div className="sale_block__new_price">{x.newPrice} ₽</div>
                                                        <div className="sale_block__old_price">{x.price} ₽</div>

                                                    </div>
                                            }
                                        </div>

                                        <div style={{ height: '2rem' }}>






                                            <Typography
                                                className="price_name"
                                                variant="body2" component="h2" style={{
                                                    whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden',
                                                    WebkitLineClamp: 2, display: '-webkit-box', WebkitBoxOrient: 'vertical',
                                                    height: '100%'
                                                }}>
                                                {x.name}

                                            </Typography>



                                        </div>


                                        <div>
                                            <PriceField item={x} tagged={((props.tagged as any).data?.filter((item: any) => item.obj.id == x.id))} refresh={() => props.refresh()} ></PriceField>
                                        </div>

                                    </CardContent>
                                </CardActionArea>
                            </Card> */}
                    </Grid>

                ))}
            </Grid>

        </div>
    );
}


