import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Paper } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import './styles.css'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TagCard(props: any) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (


    <Card className="card2" /* sx={{ maxWidth: 345, width: 200 }} */ /* style={{ borderRadius: 20 }} */>
      <div style={{ position: "relative" }} onClick={async event => {
        navigate(`/item/${props.item.id}`);
      }}>
        <CardMedia
          style={{ borderRadius: 20 }}
          component="img"
          /*    style={{ borderRadius: 20 }} */
          image={props.item.imageUrl}
          /*  height="140" */
          className="card1"
        />
      </div>

      <CardContent style={{ /* paddingBottom: 5, *//*  paddingTop: 5,  *//*  height: '60%' */ }} className="card-product">
        {/* <Box className="card_content_full" > */}
          <Typography variant="h6"/* className="card_name" *//*  style={{ fontWeight: 700 }} */>

            {props.item.name}
          </Typography>
        {/* </Box> */}
        <Box className="card_content"  >
        {/*   <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          </div> */}
          {
            props.item?.store != null
              ?
              <Box style={{ display: 'flex',/*  justifyContent: 'space-between', */ /* color: "#FAA241",  */ alignItems: 'center' }}>
                <QueryBuilderIcon style={{ fontSize: 15, marginRight: 5 }}></QueryBuilderIcon>
                <Typography variant="subtitle2" style={{/*  color: "#FAA241", */ /* fontWeight: 700, fontSize: '1rem'  */}} >{props.item?.store?.startTime}:00 - {props.item?.store?.stopTime}:00</Typography>
              </Box>
              : <div></div>
          }

        </Box>
      </CardContent>
    </Card>


    /* <Box sx={{ display: 'flex', alignItems: 'center', margin: 10 }}>
      {props.item.name}
    </Box> */



    /*   </Card> */
  );
}
