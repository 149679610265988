import { useEffect, useState } from "react";
import AutoGrid from "../Components/Products/ProductList";
import RestaurantList from "../Components/Products/RestaurantList";
import { Tag } from "../model/Tag";
import { Parameters } from "../model/Parameters";
import SearchItems from "../Core/Search/SearchItems"
import { Box, Button, Card, CardContent, CardMedia, CircularProgress, Paper, Typography } from "@material-ui/core";
import BasketButton from "../Components/Purchase/BasketButton";
import SearchResult from "../Core/Search/SearchResult"
import SaleBlock from "../Components/Sale/SaleBlock"
import CurrentStatus from "./CurrentStatus";
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import MarketBlock from "../Components/Sale/MarketBlock";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import SmthNew from "../Components/Features/SmthNew/SmthNew";
import { Skeleton } from "@mui/material";
import Grid from '@mui/material/Grid';
export default function RestListContainer(props: any) {

  const [tags, setTags] = useState<Tag[]>([]);
  const [stores, setStores] = useState<any[]>([]);

  const [items, setItems] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const [loadProducts, setLoadProducts] = useState<boolean>(true);
  const [loadRest, setLoadRest] = useState<boolean>(true);

  const [loadSearch, setLoadSearch] = useState<boolean>(true);
  const [flag, setfFlag] = useState<boolean>(false);

  const [parameters, setParameters] = useState<Parameters[]>([]);
  const [message, setMessage] = useState<string>("");


  useEffect(() => {
    // Обновляем заголовок документа с помощью API браузера

    fetch(process.env.REACT_APP_TITLE + "/tag")
      .then(res => res.json())
      .then(
        (result: Tag[]) => {

          setLoadProducts(false)
          setTags(result)

        }

      )

    fetch(process.env.REACT_APP_TITLE + "/parameters")
      .then(res => res.json())
      .then(
        (result: Parameters[]) => {

          var m = result.find(x => x.key == "STATUS_MESSAGE")
          setMessage(m?.value ?? "")
          // setParameters(result)

        }

      )


    fetch(process.env.REACT_APP_TITLE + "/store")
      .then(res => res.json())
      .then(
        (result: any[]) => {

          setLoadRest(false)
          setStores(result)
        }
      )
  }, []);

  var setLoad = () => {
    setLoadSearch(true)
  }

  var refresh = () => {
    setfFlag(!flag);
  }

  var onChange = (searchString: string) => {

    if (searchString != "") {

      fetch(process.env.REACT_APP_TITLE + `/product/search?name=${searchString}`)
        .then(res => res.json())
        .then(
          (result: any[]) => {
            setLoadSearch(false)
            setSearch(searchString)
            setItems(result)
          }
        )
    }
    else {
      setLoadSearch(false)
      setSearch("")

    }
  }

  return (

    <div>
      <div>
        {(message != "")
          ?

          <Box
            /*     onClick={() => {
    
                  navigate("/new", { state: { info: props.info, conn: props.conn } })
                }} */
            className=" css-jvzmdc  css-1y3mnrk" sx={{ mt: 2, p: 2, borderRadius: '12px', /* height: '80%' */ }}>
            {/*  <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root css-jvzmdc"> */}
            <div className="css-18mhetb">
              {/*    <div className="css-1iakqhe">
                <div className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm">
                 

                  <ComputerIcon sx={{ fontSize: 80, opacity: 0.3 }}></ComputerIcon>
                </div>
              </div> */}
              {/*      <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1 MuiGrid-direction-xs-column css-mgf9hi">
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 css-h719to"> */}
              <Box className="css-mgf9hi" >
               {/*  <Box className="css-h719to"> */}
                  {/*  <div className=' MuiTypography-root MuiTypography-h3 MuiTypography-alignCenter css-aa5ry5'><h2>Подключи свой ПК к WhyPN</h2></div> */}

                  <Box style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
                    {/*         <Typography variant="h6">Подключи WhyPN на ПК</Typography> */}
                    <Typography style={{ color: 'black' }} variant="subtitle2">{message}</Typography>

                  </Box>


                  {/*  <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-alignCenter css-aa5ry5">Подключи свой ПК к WhyPN</h3> */}
             {/*    </Box> */}
                {/* <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 css-h719to">
                      <p className="MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-1sgcksc">Daily page view</p>
                  </div> */}
              </Box>

            </div>
            {/*  </div> */}
          </Box>

          /*   <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 5 }}><Typography style={{ color: "#9266cc", textAlign: 'center' }}>{message}</Typography></div> */
          : <Skeleton

            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root css-jvzmdc  css-1y3mnrk" sx={{ mt: 2, p: 2, borderRadius: '12px', /* height: '80%' */ }}>


          </Skeleton>
        }
        {/* <MarketBlock></MarketBlock> */}
        <SmthNew></SmthNew>

        <div style={{ paddingTop: 5 }}>
          <Typography variant="h5" style={{ fontWeight: 700, fontFamily: 'Nunito-Extra-Bold' }}>Кафе и рестораны</Typography>
        </div>
        <div style={{ paddingTop: 10, }}>

          <div style={{ /* display: 'flex', */ justifyContent: 'space-between' }}>
            <div style={{ /* display: 'flex', */ justifyContent: 'space-between'/* , color: "#8920A4" */, fontFamily: 'Nunito-Extra-Bold' }}>
              {/* <QueryBuilderIcon style={{  fontSize: 20  }}></QueryBuilderIcon> */}
              <Typography style={{ /* color: "#8920A4" *//* , fontFamily: 'Nunito-Extra-Bold' */ fontSize: 14 }}>Минимальный заказ от 100 ₽
              </Typography>
              {/* <Typography style={{ color: "#8920A4", paddingLeft: 10, fontFamily: 'Nunito-Extra-Bold', fontSize: 14  }}>С 9:00 до 22:00
              </Typography> */}
            </div>

            <Typography style={{ /* color: "#8920A4", */ /* fontFamily: 'Nunito-Extra-Bold', */ fontSize: 14 }}>Доставка 199 ₽</Typography>
          </div>
        </div>
        {/* <div style={{ paddingTop: 1 }}> */}
        {/*  <SearchItems setLoad={() => setLoad()} onChange={(param: string) => onChange(param)}></SearchItems> */}
        {/* </div> */}


        <div style={{ marginBottom: 10 }}>

          {/* loadSearch */ false
            ?

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
              <CircularProgress style={{ color: '#9266cc' }} />
            </div>
            :

            (search == "") ?
              <div>


                <div >


                </div>
                <div style={{ paddingTop: 5 }}>
                  {
                    loadProducts
                      ?



                      <Grid container spacing={{ xs: 1, md: 4 }} columns={{ xs: 1, sm: 4, md: 6 }}>

                        {
                          [1, 2, 3, 4, 5, 6, 7, 8, 8].map(x => {
                            return (
                              <Grid item xs={2} sm={2} className='tag-grid'>

                                <Skeleton variant="rectangular" width={'100%'} height={118} style={{ borderRadius: 20 }} />


                                {/* <Box className="card_content_full" > */}
                                <Skeleton width={'60%'} />
                                {/* </Box> */}

                                {/*   <div style={{ display: 'flex', justifyContent: 'space-between' }}>

  </div> */}
                                <Skeleton width={'30%'} />

                              </Grid>
                            )
                          })
                        }





                      </Grid>


                      :



                      <div>

                        <AutoGrid tags={stores} ></AutoGrid>
                      </div>
                  }
                </div>
              </div>
              :
              <div>
                <SearchResult refresh={() => refresh()} items={items}></SearchResult>
              </div>
          }
        </div>
        <div>
          <CurrentStatus></CurrentStatus>

        </div>

        <div >

          <BasketButton flag={flag}></BasketButton>
        </div>
      </div>
    </div>)

}
