import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import TagCard from './RestCard'
import { CardActionArea, GridList } from '@material-ui/core';
import Grid from '@mui/material/Grid';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /*  root: {
       flexGrow: 1,
     }, */
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },

    root: {
      "& > *": {
        // margin: theme.spacing(1),
        // width: "25ch"
      }
    },
    gridList: {
      width: "100%",
      height: "auto"
    },
    card: {
      //maxWidth: 160,
      height: "100%"
    }
  }),
);


export default function AutoGrid(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  var tags = (props as any).tags;
  return (
    <div className={classes.root}>

      <Grid container spacing={{ xs:1, md: 4 }} columns={{ xs: 1, sm:4, md: 6 }}>
        {tags.map((x: any, i: any) => (
          <Grid item xs={2} sm={2} key={i}  className='tag-grid'>
            <TagCard item={x} key={i}></TagCard>
          </Grid>
        ))}
      </Grid>

    </div>
  );
}