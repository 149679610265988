import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Container, IconButton, Skeleton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Item } from '../../model/Item';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { addItem, increaseCost, reduceCost, retrieveData } from '../../services/storageService'
import gsap from "gsap";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Slider2 from '../../Core/Slider2'
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootq: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        purchase_button: {
            alignSelf: 'flex-end',
            position: 'fixed',
            bottom: 450
        },
        mainConatinerStyle: {
            flexDirection: 'column',
            flex: 1
        }
    }),
);


export default function SearchResult(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    var tags = (props as any).tags;
    var items = (props as any).items;
    var t: JSX.Element[] = [];
    const [tagged, setTagged] = useState<any[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    var onChipClicked = (id: number) => {
        setIsLoaded(true)
        navigate(`/item/${params.id}/sub/${id}`)
        //props.handleClick(id, params.id)
        //setSelectedTag(id)
    }


    useEffect(() => {
        setIsLoaded(true)
        retrieveData().then((data: any) => {

            setTagged(data);
            setIsLoaded(false)
        })

    }, [props.items]);

    var refresh = () => {
        setIsLoaded(true)
        retrieveData().then((data: any) => {
            props.refresh();
            setTagged(data);
            setIsLoaded(false)
        })
    }

    var onPlusButtonPress = (id: number, info: string) => {
        increaseCost(id, info).then(() => {
            refresh()
        })
    }

    var onMinusButtonPress = (id: number, info:string) => {
        reduceCost(id, info).then(() => {
            refresh()
        })
    }

    var navigateToDeatail = (value: any) => {

        navigate(`/detail/${value.id}`)
        /*   addItem(value).then((value: any) => {
                refresh();
                // (props as any).setTextHolder(value)
            }) */

    }



    var addToBasket = async (value: any) => {


        addItem(value).then((value: any) => {
            refresh();
            // (props as any).setTextHolder(value)
        })

    }

    return (


        <div className={classes.mainConatinerStyle}>


            < Grid container spacing={2} >
                {

                    items.length > 0
                        ?
                       
                        
                        items.map(function (x: Item, i: any) {
                            return <Grid item xs={4}>
                                <Card >
                                    <CardActionArea>


                                        <CardMedia

                                            component="img"
                                             
                                            style={{ borderRadius: 20 }}
                                            image={"" + x.imageUrl}
                                              
                                            onClick={() => {

                                                navigateToDeatail(x)
                                            }}
                                        />


                                        <CardContent style={{ padding: 10 }}>
                                            <div style={{ height: '4rem' }}>
                                                <Typography variant="body2" component="h2" style={{
                                                    whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden',
                                                    WebkitLineClamp: 3, display: '-webkit-box', WebkitBoxOrient: 'vertical',
                                                    height: '100%'
                                                }}>
                                                    {x.name}
                                                </Typography>
                                            </div>
                                            {

                                                ((tagged as any).data?.find((item: any) => item.obj.id == x.id)) != null ?
                                                    <div className={classes.card_content}>

                                                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => onMinusButtonPress(x.id, x.info)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>

                                                        <Typography variant="body2" component="h2">
                                                            {(tagged as any).data?.find((item: any) => item.obj.id == x.id).count}
                                                        </Typography>



                                                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => onPlusButtonPress(x.id, x.info)}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                    </div>
                                                    :
                                                    <div className={classes.card_content}>

                                                        <Typography variant="body2" component="h2">
                                                            {x.price} ₽
                                                        </Typography>

                                                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => addToBasket(x)}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                    </div>
                                            }
                                        </CardContent>
                                    </CardActionArea>
                                </Card>




                            </Grid>;



                        })

                        :
                        <div style={{ display: 'flex', justifyContent: 'center', width:'100%', paddingTop:20 }}>
                            <Typography >К сожалению, ничего такого у нас нет :(</Typography>
                        </div>
                }




            </Grid>

        </div >
    );
}