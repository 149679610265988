export async function addOtherThing(item: any) {


     
    localStorage.setItem('@other', JSON.stringify(item));


}


export async function getOtherThing() {

 
    let value = await localStorage.getItem("@other");
    return value != null ? JSON.parse(value as any) : null;

}
