import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Badge, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import { addItem, increaseCost, reduceCost, retrieveData } from '../../services/storageService';
import React from 'react';
import { Breadcrumbs, IconButton, Link, Typography } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BasketButton from "../../Components/Purchase/BasketButton";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootq: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        purchase_button: {
            alignSelf: 'flex-end',
            position: 'fixed',
            bottom: 450
        },
        mainConatinerStyle: {
            flexDirection: 'column',
            flex: 1
        }
    }),
);



export default function PriceField(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();
    const [flag, setfFlag] = useState<boolean>(false);
    const [tagged, setTagged] = useState<any[]>([]);
    const [product, setProduct] = React.useState<any>({
        count: 0,
        cost: 0,
        delivery_cost: 0
    });

    useEffect(() => {


    }, []);

    var onPlusButtonPress = (item: any) => {

        if (item.options == null) {
            increaseCost(item.id, item.info).then(() => {
                props.refresh()
            })
        }
        else {
            navigate(`/detail/${item.id}`)
        }

    }

    var onMinusButtonPress = (id: number, info: string) => {

        var a = ((props.tagged as any).find((item: any) => item.obj.id == id));
        reduceCost(id, a.obj.info).then(() => {
            props.refresh()
        })
    }


    var refresh = () => {
        // setIsLoaded(true)
        retrieveData().then((data: any) => {
            setfFlag(!flag);
            setTagged(data);
            // setIsLoaded(false)
        })
    }

    var addToBasket = async (value: any) => {
         
        if (value.options == null) {
            addItem(value).then((value: any) => {
                props.refresh();
                // (props as any).setTextHolder(value)
            })
        }
        else {
            navigate(`/detail/${value.id}`)
        }
        //.navigate(`/detail/${value.id}`)


    }

    var add = (accumulator: any, a: any) => {
        return accumulator + a;
    }

    useEffect(() => {

        /*  setIsLoaded(true) */
        retrieveData().then((data: any) => {

            setTagged(data);
            /*  setIsLoaded(false) */
        })

    }, []);

    var getCount = () => {
        if (props.tagged.length > 1) {
            return props.tagged.map((x: any) => x.count).reduce(add, 0)
        }
        else {
            return props.tagged[0].count;
        }

    }

    return (

        <div >
            {
                props.tagged == null || props.tagged.length == 0
                    ? <div className={classes.card_content} style={{ background: '#ffffff', borderRadius: '20px', color: 'blaack' }}>
                        {/*  <div className="price_block">
                            {
                                props.item.newPrice == null
                                    ?

                                    <div className="block__old_price">{props.item.price}</div>

                                    :
                                    <div className="price_block_for_sale">
                                        <div className="sale_block__new_price">{props.item.newPrice} ₽</div>
                                        <div className="sale_block__old_price">{props.item.price} ₽</div>

                                    </div>
                            }
                        </div> */}
                        <div className="add_button_1_1">
                            <IconButton title='' className="add_button" size="medium" color="primary" aria-label="upload picture" component="span" onClick={() => addToBasket(props.item)}>
                                <Typography variant="body2" style={{color:'black'}}>В корзину</Typography>
                                {/*    <AddIcon className="add_button_pic" /> */}
                            </IconButton>
                        </div>
                    </div>
                    :

                    <div className={classes.card_content} style={{ background: '#ffffff', borderRadius: '20px', color: 'blaack'}}>

                        <IconButton className="add_button" color="primary" aria-label="upload picture" component="span" onClick={() => onMinusButtonPress(props.item.id, props.item.options)}>
                            <RemoveIcon className="add_button_pic" />
                        </IconButton>

                        <div className="price_block" style={{ textAlign: 'center' }}>
                            <div className="block_count">
                                {getCount()}
                            </div>
                        </div>



                        <IconButton className="add_button" color="primary" aria-label="upload picture" component="span" onClick={() => onPlusButtonPress(props.item)}>
                            <AddIcon className="add_button_pic" />
                        </IconButton>
                    </div>


            }
        </div >
    );
}